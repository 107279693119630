import { graphql, PageProps, useStaticQuery } from "gatsby"
import React, { ReactElement } from "react"
import Slider from "react-slick"
import LinkedInLog from "../../image/logo/linkedin.png"
import ReactMarkdown from "react-markdown"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
}

const NewsQuery = graphql`
  query NewsQuery {
    allContentfulNewsAndUpdates(
      sort: { order: DESC, fields: createdAt }
      limit: 5
      filter: { id: { ne: "55fd7d95-316f-57af-a1e6-12d22a37eb11" } }
    ) {
      nodes {
        title
        mediaLink
        description {
          description
        }
        media {
          fluid {
            src
          }
        }
      }
    }
  }
`

export const MediaRender = ({ media }: { media: string }) => {
  if (
    media.toLowerCase().includes("png") ||
    media.toLowerCase().includes("jpeg") ||
    media.toLowerCase().includes("jpg")
  ) {
    return (
      <img src={media} className="h-full  w-full object-cover" alt={media} />
    )
  }
  let link = media
  if (media.toLowerCase().includes("youtube")) {
    if (!link.includes("embed")) {
      link = `https://www.youtube.com/embed/${
        media.split("=")[1].split("&")[0]
      }`
    }
  }
  return (
    <iframe
      id="ytplayer"
      src={link}
      width="100%"
      height="500px"
      frameBorder="0"
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
      }}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
    />
  )
}
export default function NewsAndUpdates(): ReactElement {
  const {
    allContentfulNewsAndUpdates: { nodes },
  } = useStaticQuery(NewsQuery) as {
    allContentfulNewsAndUpdates: {
      nodes: [
        {
          title: string
          mediaLink?: string
          description: {
            description: string
          }
          media?: {
            fluid: {
              src: string
            }
          }
        }
      ]
    }
  }

  return (
    <div className="flex items-center justify-center text-center text-white bg-br-primary-blue flex-col px-8 pb-10 pt-10">
      <h1 className="text-2xl md:text-4xl">News &amp; Updates</h1>
      <div className="w-full block mt-10">
        <div className="w-full block news-and-updates">
          <Slider {...settings} pauseOnHover>
            {nodes.map(news => (
              <div key={news.title}>
                <div className="flex items-center justify-center px-2 py-2">
                  <div className="max-w-[700px]  rounded-2xl overflow-hidden">
                    <div className="bg-white text-black font-medium text-lg text-left px-4 py-4">
                      {news.title}
                    </div>
                    <div className="h-[230px] sm:h-[400px] w-[700px] overflow-hidden max-w-[100%] relative">
                      <MediaRender
                        media={news.mediaLink || news.media?.fluid.src || ""}
                      />
                    </div>
                    <div className="bg-white text-black font-medium text-lg text-left px-4 py-4">
                      <p className="font-normal text-base text-gray-600 mt-2 ">
                        <ReactMarkdown
                          children={news.description?.description}
                          components={{
                            a: ({ node, ...props }) => (
                              <a
                                className="underline text-br-primary-blue"
                                {...props}
                              />
                            ),
                          }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <a
        href="https://www.linkedin.com/company/lawlite/"
        className="hover:text-white"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="font-medium text-xl lg:text-2xl mt-20 flex items-center" id="subscribe">
          Join our LinkedIn Page{" "}
          <img
            src={LinkedInLog}
            className="object-cover h-9 ml-2"
            alt={"LinkedIn Logo"}
          />
        </div>
      </a>
    </div>
  )
}
